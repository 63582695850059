<template>
	<div style="padding: 10px;">
		<Navbar />
	    <Menu />
	    <div class="app-content content" id="hehe">
	        <div class="content-wrapper" 
				style="margin-top: 0px;
						padding-left: 10px;
						padding-right: 0px;
						padding-top: 10px;">
				<div class="content-body">
					<div class="mb-1" v-if="$route.meta.pageTitle">
						<span class="font-700">{{ $route.meta.pageTitle }}</span>
					</div>
					<router-view/>
				</div>
	        </div>
	    </div>
		<div class="sidenav-overlay"></div>
	</div>
</template>

<script>
import Navbar from '@/layouts/components/Navbar'
import Menu from '@/layouts/components/Menu'
export default {
	components: {
		Navbar,
		Menu
	},
	methods: {
		logout() {
			let _ = this
			window.$(function(){                
					window.$('body').removeClass('menu-collapsed')
					window.$('body').addClass('menu-expanded')
			})
			_.$store.dispatch('logout')
			window.location = this.$siteUrl + '/login'
		}
	},
	mounted() {
		let _ = this
		window.onunload = function () {
  		_.logout()
		}
	},
}
</script>