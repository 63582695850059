<template>
	<nav class="header-navbar navbar-expand-lg navbar navbar-with-menu navbar-light navbar-shadow" style="min-height:unset; height: 34px;" id="navbar">
        <div class="navbar-wrapper">
            <div class="navbar-container content">
                <div class="navbar-collapse" id="navbar-mobile">
                    <div class="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                        <img :src="$siteUrl + '/assets/img/sumco-navbar.png'" alt="" class="img-fluid mr-2">
                        <i class="text-white"><b>EDM</b> <small>Electronic Document Management System.</small></i>
                        &nbsp;<a :href="$siteUrl + '/version'" style="text-decoration: none; color: #FFFFFF !important" class="text-white pull-right"><small>version</small></a>
                    </div>   
                    <ul class="nav navbar-nav float-right">
                        <!-- <li class="dropdown dropdown-notification nav-item align-self-center">
                            <a class="nav-link nav-link-label p-0" href="#" data-toggle="dropdown">
                                <i class="text-center fa fa-bell text-white"></i>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-media dropdown-menu-right">
                                <li class="dropdown-menu-header">
                                    <div class="dropdown-header m-0">
                                        <span class="notification-title">Notifications</span>
                                    </div>
                                </li>
                                <li>
                                    <div class="count-notif-block">
                                        <p class="font-600 text-primary mb-0">All ({{ notifications.length }})</p>
                                        <a href="javascript:void(0)" class="font-500 text-primary mb-0" @click="readAllNotif()">Mark All As Read</a>
                                    </div>
                                </li>
                                <li class="scrollable-container media-list">
                                    <Notification v-for="(notif, ix) in notifications" :key="ix" :data="notif" @readNotif="readNotif()" />
                                </li>
                                <li v-if="false" class="dropdown-menu-footer">
                                    <a class="dropdown-item p-1 text-center" href="javascript:void(0)">View all notifications</a>
                                </li>
                            </ul>
                        </li>
                        <li class="align-self-center text-white ml-1">|</li> -->
                        <li class="dropdown dropdown-user nav-item">
                            <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                                <div class="user-nav ml-1 text-right">
                                    <span class="text-white f-12">{{ user.user_detail }} ({{ user.level }})<i class="fa fa-chevron-down ml-1"></i></span>
                                </div>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <router-link to="/change-password" class="dropdown-item">
                                    <i class="feather icon-lock"></i> Change password
                                </router-link>
                                <a href="javascript:;" @click="logout()" class="dropdown-item">
                                	<i class="feather icon-power"></i> Keluar
                                </a>
                            </div>
                        </li>
                    </ul>                 
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
// import Notification from './Notification.vue'
import $ from 'jquery'

export default {
    components: {
        // Notification
    },
    data() {
        return {
            isFullScreen: 0,
            notifications: [],
            temp_photo: null,
            user: JSON.parse(localStorage.getItem('user'))
        }
    },
	methods: {
        readNotif(notification_id) {
            let _ = this
            _.notifications = _.notifications.map(n => {
                if ( notification_id == n.id ) {
                    return { ...n, is_read: true }
                } else {
                    return n
                }
            })
        },
        readAllNotif(){
            //
        },
        logout() {
            let _ = this
            $(function(){                
                $('body').removeClass('menu-collapsed')
                $('body').addClass('menu-expanded')
            })
			_.$store.dispatch('logout')
            window.location = this.$siteUrl + '/login'
        }
	},
    mounted() {
        let _ = this
        _.$root.$on('changeProfile', (data) => {
            _.user = data
            localStorage.setItem('user', JSON.stringify(_.user))
        })
    },
}
</script>