<template>
    <div>
        <div class="main-menu menu-fixed menu-light menu-accordion menu-shadow" v-can="['admin', 'admin-level']" data-scroll-to-active="true" style="background-color: #FFF;" id="menuSidebar">
            <vue-resizable width="280px" @resize:move="eHandler" :disableAttributes="['l', 't']">
                <router-link to="/">
                    <div class="navbar-header pointer mb-1">
                        <div class="row pl-1 pr-1">
                            <img :src="$siteUrl + '/assets/img/sumco-sidebar.png'" class="img-fluid" alt="">
                            <div class="col text-right align-self-center p-0">
                                <a class="nav-link nav-menu-main menu-toggle d-none-collapsed" href="#" data-toggle="collapse">
                                    <img :src="$siteUrl + '/assets/img/icon/chevron-left-circle.png'" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </router-link>

                <div class="main-menu-content">
                    <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation" style="padding: 0px 12px">
                        <li class="nav-item pl-0 pr-0" v-for="(data, idx) in menus" :key="idx" 
                            :class="isActive(data) + (data.sub && data.sub.length > 0 ? ' has-sub' : '') + (data.sub && data.sub.length > 0 && isActive(data) ? ' open' : '')">
                            <router-link :to="data.url" style="margin-bottom: 5px;">
                                <i class="material-icons f-12">{{data.icon}}</i>
                                <span class="menu-title f-12 align-self-center font-400">{{data.name}}</span>
                                <span class="badge badge badge-danger badge-pill float-right" v-if="data.counter > 0">{{data.counter}}</span>
                            </router-link>
                            <ul class="menu-content">
                                <li v-for="(dt, i) in data.sub" :key="i">
                                    <router-link :to="dt.url">
                                        <i class="ml-2 fa fa-circle"></i>
                                        <span class="menu-item f-12">{{dt.name}}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </vue-resizable>
        </div>
        <div style="position:fixed; width: 290px; top: 10px; overflow-y:auto; overflow-x:hidden; scrollbar-width: thin;" v-can="['user', 'user-dokumen']" id="sidebarUser">
            <vue-resizable width="280px" @resize:move="eHandler" @resize:end="onResizeEnd" :disableAttributes="['l', 't']" style="height:auto;">
                <div class="card card-custom mb-1">
                    <div class="card-body pl-0 pr-0 pt-1 pb-0">
                        <div class="table-responsive" style="scrollbar-width:thin; overflow-x:hidden;overflow-y:hidden" id="dash">
                            <div class="row pl-2 pr-2 mb-1" style="min-width: 280px;">
                                <i class="material-icons f-25 tetx-primary mr-1" style="margin-left: 5px;">grid_view</i>
                                <p class="f-12 font-600 text-primary align-self-center mb-0">Dashboard</p>
                            </div>
                            <div class="row pl-1 pr-1 mb-1" style="min-width: 280px;">
                                <div class="col-4">
                                    <select name="" id="" class="form-control" v-model="filter">
                                        <option value="">Type</option>
                                        <option value="section">Section</option>
                                        <option value="advance">Advance</option>
                                    </select>
                                </div>
                                <div class="col pl-0">
                                    <input type="text" class="form-control" placeholder="Search number or name doc" v-model="search" :disabled="filter != 'advance'">
                                </div>
                            </div>
                            <vue-resizable width="280px" :disableAttributes="['l', 't', 'w']" @resize:move="eHandler2" id="filetree" height="400px">
                                <div style="scrollbar-width: thin; height: 99%; overflow-y:auto; overflow-x: auto" id="heuheu">
                                    <vue-tree-list :model="tree.document"
                                        v-if="isShow && tree.document && ( filter == '' )"
                                        v-bind:default-expanded="false">
                                        <template v-slot:leafNameDisplay="slotProps">
                                            <span style="vertical-align: sub;" class="pointer" v-if="slotProps.model.document_number"  @click="previewFile(slotProps.model)">
                                                <span class="f-16 text-primary" style="margin-right: 5px;"></span>
                                                <i class="fa fa-file text-primary f-12"></i>
                                                [{{ slotProps.model.document_number }}-{{ numToSSColumn(slotProps.model.initial) }}] {{ slotProps.model.title }}
                                            </span>
                                            <span style="vertical-align: sub;" v-else>
                                                <span class="f-16 text-primary" style="margin-right: 5px;" v-if="slotProps.model.main_id"></span>
                                                <img :src="$siteUrl + '/assets/img/icon/folder.png'" alt="" class="img-fluid"> 
                                                {{ slotProps.model.code }} - {{ slotProps.model.title }}
                                            </span>
                                        </template>
                                        <span class="icon d-none" slot="treeNodeIcon"></span>
                                    </vue-tree-list>
                                    <vue-tree-list :model="tree.section"
                                        v-if="isShow && tree.section && ( filter == 'section')"
                                        v-bind:default-expanded="false">
                                        <template v-slot:leafNameDisplay="slotProps">
                                            <span style="vertical-align: sub;" class="pointer" v-if="slotProps.model.type == 'file'"  @click="previewFile(slotProps.model)">
                                                <span class="f-16 text-primary" style="margin-right: 5px;"></span>
                                                <i class="fa fa-file text-primary f-12"></i>
                                                [{{ slotProps.model.code }}-{{ numToSSColumn(slotProps.model.initial) }}] {{ slotProps.model.title }}
                                            </span>
                                            <span style="vertical-align: sub;" v-else>
                                                <span class="f-16 text-primary" style="margin-right: 5px;" v-if="slotProps.model.main_id"></span>
                                                <img :src="$siteUrl + '/assets/img/icon/folder.png'" alt="" class="img-fluid"> 
                                                {{ slotProps.model.code }} - {{ slotProps.model.title }}
                                            </span>
                                        </template>
                                        <span class="icon d-none" slot="treeNodeIcon"></span>
                                    </vue-tree-list>
                                    <vue-tree-list :model="tree.file" style="min-width: fit-content;"
                                        v-if="isShow && tree.file && ( filter == 'advance')"
                                        v-bind:default-expanded="false">
                                        <template v-slot:leafNameDisplay="slotProps">
                                            <span style="vertical-align: sub;" class="pointer" @click="previewFile(slotProps.model)">
                                                <i class="fa fa-file text-primary f-12"></i>
                                                [{{ slotProps.model.document_number }}-{{ numToSSColumn(slotProps.model.initial) }}] {{ slotProps.model.title }}
                                            </span>
                                        </template>
                                        <span class="icon d-none" slot="treeNodeIcon"></span>
                                    </vue-tree-list>
                                </div>
                            </vue-resizable>
                        </div>
                    </div>
                </div>

                <div class="card card-custom">
                    <div class="card-body p-0">
                        <div class="table-responsive" id="menuDetails" style="scrollbar-width:thin;overflow-x:hidden;overflow-y:hidden">
                            <ul class="nav nav-tabs mb-0" id="myTab" role="tablist" style="min-width: 280px;">
                                <li class="nav-item">
                                    <a :class="state == 'relation' ? 'active' : ''"
                                        @click="state = 'relation'"
                                        class="nav-link text-black f-12" 
                                        id="relation-tab" 
                                        data-toggle="tab" 
                                        href="#relation" 
                                        role="tab" 
                                        aria-controls="relation" 
                                        aria-selected="true">
                                        Relation
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a :class="state == 'properties' ? 'active' : ''"
                                        @click="state = 'properties'"
                                        class="nav-link text-black f-12" 
                                        id="properties-tab" 
                                        data-toggle="tab" 
                                        href="#properties" 
                                        role="tab" 
                                        aria-controls="properties" 
                                        aria-selected="false">
                                        Properties
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a :class="state == 'note' ? 'active' : ''"
                                        @click="state = 'note'"
                                        class="nav-link text-black f-12" 
                                        id="note-tab" 
                                        data-toggle="tab" 
                                        href="#note" 
                                        role="tab" 
                                        aria-controls="note" 
                                        aria-selected="false">
                                        Note
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a :class="state == 'history' ? 'active' : ''"
                                        @click="state = 'history'"
                                        class="nav-link text-black f-12" 
                                        id="history-tab" 
                                        data-toggle="tab" 
                                        href="#history" 
                                        role="tab" 
                                        aria-controls="history" 
                                        aria-selected="false">
                                        History
                                    </a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent" style="min-width: 270px;scrollbar-width: thin; overflow-y:auto; overflow-x:auto;" v-if="fileDetails">
                                <div :class="state == 'relation' ? 'active' : ''" class="tab-pane fade show" id="relation" role="tabpanel" aria-labelledby="relation-tab">
                                    <table class="table table-striped mb-0">
                                        <tbody v-if="fileDetails.related_document">
                                            <tr v-for="related_document in JSON.parse(fileDetails.related_document).filter(x=>x.document_number)" :key="related_document.id"
                                                @click="previewDocument(related_document)" class="pointer">
                                                <td>{{ related_document.document_number }}-{{ numToSSColumn(related_document.initial) }}</td>
                                                <td>{{ related_document.title }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div :class="state == 'properties' ? 'active' : ''" class="tab-pane fade show" id="properties" role="tabpanel" aria-labelledby="properties-tab">
                                    <table class="table table-striped f-10 mb-0" style="white-space: nowrap;">
                                        <tbody>
                                            <tr style="border-0">
                                                <td>Document No.</td>
                                                <td>:</td>
                                                <td class="text-left">{{ fileDetails.document_number }}-{{ numToSSColumn(fileDetails.initial) }}</td>
                                            </tr>
                                            <tr style="border-0">
                                                <td>Formerly</td>
                                                <td>:</td>
                                                <td class="text-left">{{ fileDetails.formerly }}</td>
                                            </tr>
                                            <tr style="border-0">
                                                <td>Document Title</td>
                                                <td>:</td>
                                                <td class="text-left">{{ fileDetails.title }}</td>
                                            </tr>
                                            <tr style="border-0" v-for="(section, index) in JSON.parse(fileDetails.section)" :key="index">
                                                <td>Section (Level {{index+1}})</td>
                                                <td>:</td>
                                                <td class="text-left">{{ section.code }} - {{ section.title }}</td>
                                            </tr>
                                            <tr style="border-0" v-for="(originator, index) in JSON.parse(fileDetails.originator)" :key="index">
                                                <td>Originator ({{index+1}})</td>
                                                <td>:</td>
                                                <td class="text-left">
                                                    {{ originator.user_detail }} - 
                                                    {{ JSON.parse(originator.position_id)[0].position }} -
                                                    {{ originator.date | moment('YYYY/MM/DD') }}
                                                </td>
                                            </tr>
                                            <tr style="border-0" v-for="(reviewer, index) in JSON.parse(fileDetails.reviewer)" :key="index">
                                                <td>Reviewer ({{index+1}})</td>
                                                <td>:</td>
                                                <td class="text-left">
                                                    {{ reviewer.user_detail }} - 
                                                    {{ JSON.parse(reviewer.position_id)[0].position }} -
                                                    {{ reviewer.date | moment('YYYY/MM/DD') }}
                                                </td>
                                            </tr>
                                            <tr style="border-0" v-for="(approval, index) in JSON.parse(fileDetails.approval)" :key="index">
                                                <td>Approval ({{index+1}})</td>
                                                <td>:</td>
                                                <td class="text-left">
                                                    {{ approval.user_detail }} - 
                                                    {{ JSON.parse(approval.position_id)[0].position }} -
                                                    {{ approval.date | moment('YYYY/MM/DD') }}
                                                </td>
                                            </tr>
                                            <tr style="border-0">
                                                <td>Effective Date</td>
                                                <td>:</td>
                                                <td class="text-left">{{ fileDetails.effective_date | moment('YYYY/MM/DD') }}</td>
                                            </tr>
                                            <tr style="border-0">
                                                <td>Next Review Date</td>
                                                <td>:</td>
                                                <td class="text-left">{{ fileDetails.next_review_date | moment('YYYY/MM/DD') }}</td>
                                            </tr>
                                            <tr style="border-0" v-for="(departement, index) in JSON.parse(fileDetails.departement)" :key="index">
                                                <td>Departemen ({{index+1}})</td>
                                                <td>:</td>
                                                <td class="text-left">
                                                    {{ departement.title }}
                                                </td>
                                            </tr>
                                            <tr style="border-0" v-for="(pelaksana, index) in JSON.parse(fileDetails.pelaksana)" :key="index">
                                                <td>Pelaksana ({{index+1}})</td>
                                                <td>:</td>
                                                <td class="text-left">
                                                    {{ pelaksana.position }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div :class="state == 'note' ? 'active' : ''" class="tab-pane fade show" id="note" role="tabpanel" aria-labelledby="note-tab">
                                    <table class="table table-striped f-10 mb-0" style="white-space: nowrap;">
                                        <tbody>
                                            <tr v-for="(note, index) in notes" :key="index">
                                                <td>Rev {{ numToSSColumn(note.initial) }} - {{ note.created_at | moment('YYYY/MM/DD') }}</td>
                                                <td>{{ note.note }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div :class="state == 'history' ? 'active' : ''" class="tab-pane fade show" id="history" role="tabpanel" aria-labelledby="history-tab">
                                    <table class="table table-striped f-10 mb-0" style="white-space: nowrap;">
                                        <tbody>
                                            <tr v-for="(history, index) in histories" :key="index">
                                                <td>Rev {{ numToSSColumn(history.initial) }} - {{ history.created_at | moment('YYYY/MM/DD') }}</td>
                                                <td>{{ history.history }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <p class="f-10 text-center mt-1" v-else>No File Choosen</p>
                        </div>
                    </div>
                </div>
            </vue-resizable>
        </div>
    </div>
</template>
<script>
import { VueTreeList, Tree } from 'vue-tree-list'

export default {
    components: {
        VueTreeList
    },
    data() {
        return {
            menus: [
                {
                    name: 'Manage Document',
                    url: '/document',
                    icon: 'inventory_2',
                    sub: [
                        {
                            name: 'Reserve & Registration',
                            url: '/document/reserve-and-registration'
                        },
                        {
                            name: 'Reports',
                            url: '/document/reports'
                        },
                        {
                            name: 'Reset of Doc Must Read: Based On Document',
                            url: '/document/reset-document'
                        },
                        {
                            name: 'Reset of Doc Must Read: Based On User',
                            url: '/document/reset-user'
                        }
                    ]
                },
                {
                    name: 'Manage Master File',
                    url: '/master-file',
                    icon: 'description'
                },
                {
                    name: 'User & Role Management',
                    url: '/user-and-role-management',
                    icon: 'person'
                }
            ],
            tree: {
                document: null,
                section: null,
                file: null
            },
            isExpanded: false,
            isShow: true,
            filter: '',
            fileDetails: null,
            files: [],
            search: '',
            backup: {
                documents: [],
                sections: [],
                files: []
            },
            histories: [],
            notes: [],
            user: null,
            position: null,
            state: 'relation',
        }
    },
    methods: {
        isActive(data){
            let _ = this
            if (data.url == '/' && _.$route.fullPath == '/') {
                return 'active';
            } else if (data.url != '/' && (data.url == _.$route.fullPath || _.$route.fullPath.indexOf(data.url) != -1)){
                return 'active';
            }
        },
        eHandler(data) {
            document.getElementById("hehe").style.marginLeft = data.width + "px";
            document.getElementById("menuSidebar").style.width = data.width + "px";
            document.getElementById("menuSidebar").style.height = data.height + "px";
            document.getElementById("navbar").style.width = "calc( 100vw - (100vw - 100%) - calc(2.2rem * 2) - " + (data.width - 50) + "px"+")";
            document.getElementById("sidebarUser").style.width = data.width+10 + "px";
            document.getElementById("embed").style['z-index'] = -1;

            document.getElementById("filetree").style.width = data.width + "px";
            document.getElementById("heuheu").style.width = data.width + "px";
        },
        onResizeEnd() {
            document.getElementById("embed").style['z-index'] = 1;
        },
        eHandler2(data) {
            document.getElementById("menuDetails").style.height = window.innerHeight - 549 + ( 400 - data.height) + "px";
            setTimeout(() => {
                document.getElementById("myTabContent").style.height = 'calc('+ document.getElementById("menuDetails").style.height +' - 40px)'
            }, 100);
        },
        getDocumentTree() {
            let _ = this
            _.axios.get('/v2/repositories/master-file?showFile=true&modul=dashboard&search=' + _.search)
                .then( resp => {
                    _.tree.document = new Tree(resp.data.data)
                    _.backup.document = resp.data.data

                    setTimeout(() => {
                        window.$(".vtl-node-main").attr('draggable', false);
                    }, 100);
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getSectionTree() {
            let _ = this
            _.axios.get('/v2/repositories/section?showFile=true&modul=dashboard')
                .then( resp => {
                    _.tree.section = new Tree(resp.data.data)
                    _.backup.section = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getFileTree() {
            let _ = this
            _.axios.get('/v1/repositories/document?modul=dashboard')
                .then( resp => {
                    _.tree.file = new Tree(resp.data.data)
                    _.backup.file = resp.data.data
                    _.files = resp.data.data
                })
                .catch(err=>{
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                })
        },
        getHistory( data ) {
            let _ = this
            _.histories = []

            _.axios.get('/v2/document/history/' + data.id)
                .then(resp => {
                    _.histories = resp.data.data
                })
        },
        getNote( data ) {
            let _ = this
            _.notes = []

            _.axios.get('/v2/document/note/' + data.id)
                .then(resp => {
                    _.notes = resp.data.data
                })
        },
        previewDocument( data ) {
            let _ = this
            // _.fileDetails = data
            _.$root.$emit('previewFile', data)
        },
        previewFile( data ) {
            let _ = this
            let search = _.files.map( x => x.document_number ).indexOf( (data.code ? data.code : data.document_number ) )
            if (search != -1) {
                let file = _.files[search]
                _.previewDocument(file)
            }
        },
        disableDrag() {
            setTimeout(() => {
                window.$(".vtl-node-main").attr('draggable', false);
            }, 100);
        }
    },
    mounted() {
        let _ = this

        _.getDocumentTree()
        _.getSectionTree()
        _.getFileTree()

        _.$root.$on("updateFileDetails", (data) => {
            _.fileDetails = null
            setTimeout(() => {
                _.fileDetails = data
            }, 100);
        })
        _.$root.$on("updateFile", (data) => {
            if (data) {
                _.getFileTree()
            }
        })

        setTimeout(() => {
            document.getElementById('menuDetails').style.height = window.innerHeight - 549 + 'px'
        }, 100);
    },
    watch: {
        search: function() {
            let _ = this
            
            let data = []

            data = JSON.parse(JSON.stringify(_.backup.file)) 

            if ( _.search ) {
                data = data.filter(function(data) {
                    return data.document_number.toLowerCase().indexOf(_.search.toLowerCase()) > -1 || 
                        data.title.toLowerCase().indexOf(_.search.toLowerCase()) > -1 
                })
            }
            _.tree.file = new Tree(data)
            
            _.$forceUpdate()
        },
        fileDetails: function() {
            let _ = this

            if ( _.fileDetails ) {
                _.getHistory(_.fileDetails)
                _.getNote(_.fileDetails)

                setTimeout(() => {
                    document.getElementById("myTabContent").style.height = 'calc('+ document.getElementById("menuDetails").style.height +' - 40px)'
                }, 100);
            }
        },
        filter: function() {
            this.search = ''
            this.isShow = false
            setTimeout(() => {
                this.isShow = true
            }, 100);
        }
    }
}
</script>